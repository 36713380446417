.ant-pagination-item-active {
  border-color: #f7931d;
  color: #f7931d;
}
.ant-pagination-item-active:hover {
  border-color: #f7931d;
  color: #f7931d;
}
.ant-pagination-item-active a {
  color: #f7931d;
}
.ant-pagination-item-active a:hover {
  color: #f7931d;
}

.ant-dropdown-menu-item-selected,
.ant-dropdown-menu-submenu-title-selected,
.ant-dropdown-menu-item-selected > a,
.ant-dropdown-menu-submenu-title-selected > a {
  color: #f7931d;
}

.ant-btn-link:hover {
  color: #f7931d;
}
.ant-btn-link:focus {
  color: #f7931d;
}
.ant-btn-link {
  color: #f7931d;
}

.ant-layout-sider-trigger {
  background-color: #f7931d;
}

.ant-page-header {
  padding: 16px 0;
}

.ant-breadcrumb a:hover {
  color: #f7931d;
}

.ant-popover-buttons button:hover {
  border-color: #f7931d;
  color: #f7931d;
}

a.ant-typography {
  color: #f7931d;
}
a.ant-typography:hover {
  color: #f7931d;
}

.ant-table-filter-trigger.active {
  color: #f7931d;
}

.ant-table-column-sorter-up.active,
.ant-table-column-sorter-down.active {
  color: #f7931d;
}

/* Styles for contents of each page */
.layout-background {
  margin: 0px 20px 20px 20px;
}
.content-area {
  margin: 20px;
  padding: 20px;
  background-color: #f9fafb;
}

.space-between-header {
  display: flex;
  justify-content: space-between;
  background-color: #fff;
}
@media (max-width: 1024px) {
  .space-between-header {
    display: flex;
    justify-content: space-between;
    background-color: #fff;
  }
}

.content-space-between-header {
  padding: 15px 20px;
  display: flex;
  justify-content: space-between;
  background-color: #fff;
}
@media (max-width: 600px) {
  .content-space-between-header {
    padding: 5px 20px;
    justify-content: center;
  }
}

.orange-button {
  background-color: #f7931d;
  color: #fff;
}
.orange-button:hover {
  background-color: #fff;
  border-color: #f7931d;
  color: #f7931d;
}
.orange-text-button {
  color: #f7931d;
}
.orange-text-button:hover {
  color: #f7931d;
  border-color: #f7931d;
}
.action-button {
  background-color: lightcoral;
  display: flex;
  justify-content: flex-end;
}

.pdf-page {
  border: 2px;
  border-style: solid;
  height: 100%;
  /* max-height: 450px; */
  overflow: auto;
}

.site-card-wrapper {
  background: #ececec;
  margin-bottom: 12px;
}

/* Stepper */
.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
  padding: 5px 0 10px 16px;
}
.ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
  color: #f7931d;
  stroke: #f7931d;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
  background: #f7931d;
  border: #f7931d;
}
.ant-steps-item-finish .ant-steps-item-icon {
  border-color: #52c41a;
}
.ant-steps-item-icon .ant-steps-icon {
  color: #f7931d;
}
.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: #52c41a;
}
/* todo: responsive */
.ant-steps-item-title {
  font-size: 13px;
}
.ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after {
  background-color: #52c41a;
}
.ant-steps-item-icon .ant-steps-icon {
  color: #c2c2c2;
}
.ant-progress-status-success
  .ant-progress-inner:not(.ant-progress-circle-gradient)
  .ant-progress-circle-path {
  color: #f7931d;
}
.ant-progress-status-success
  .ant-progress-inner:not(.ant-progress-circle-gradient)
  .ant-progress-circle-path {
  stroke: #f7931d;
}
.ant-steps-with-progress .ant-steps-item-icon .ant-progress {
  top: -4px;
}

/* Upload file */
.ant-upload.ant-upload-drag {
  /* height: 30vh; */
  border: 1px solid #d9d9d9;
}
@media (max-width: 768px) {
  .ant-upload.ant-upload-drag {
    /* height: 40vh; */
    border: 1px solid #d9d9d9;
  }
}

.ant-slider-step {
  background: rgba(0, 0, 0, 0.05);
}

div.ant-typography, .ant-typography p {
  margin-bottom: 0;
}
 
/* Datepicker */
.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border: 1px solid #f7931d;
}
.ant-picker-today-btn {
  color: #f7931d;
}
.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  background: #f7931d;
}

/* Radio button */
.ant-radio-button-wrapper:hover{
  color: #f7931d;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled){
  color: #f7931d;
  border-color: #f7931d!important;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover{
  background-color: #f7931d;
  color: white;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before{
  color: #f7931d;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before:hover{
  color: #f7931d;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before{
  background-color: #f7931d;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before:hover{
  background-color: #f7931d;
  border-color: #f7931d;
}

/* Divider */
.ant-divider-horizontal{
  margin: 10px 0;
}

/* Statistics */
/* todo: might need responsive */
.ant-statistic-title{
  font-size: 12px;
}

/* Tabs */
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
  color: #f7931d;
}
.ant-tabs-tab:hover{
  color: #f7931d;
}
.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active, .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab-active{
  color: #f7931d;
}

/* List */
.ant-list-item-meta-title > a:hover{
  color: #f7931d;
}

/* Select */
.ant-select:not(.ant-select-disabled):hover .ant-select-selector{
  border-color: #f7931d;
}
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector{
  border-color: #f7931d;
  box-shadow: 0 0 0 2px #FFDDB5;
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled){
  background-color: #FFDDB5;
}

/* DateRange picker */
.ant-picker-focused{
  border-color: #f7931d;
  box-shadow: 0 0 0 2px #FFDDB5;
}
.ant-picker-range:hover{
  border-color: #f7931d;
}
.ant-picker-range .ant-picker-active-bar{
  background: #f7931d;
}

/* Checkbox */

.ant-checkbox-checked .ant-checkbox-inner{
  background-color: #f7931d;
  border-color: #f7931d;
}

/* Typography */
.ant-typography{
  color: #f7931d;
}

.floating-button{
  position: fixed;
  bottom: 40px;
  right: 50px;
}

/* Form */
.ant-input:placeholder-shown:hover{
  border-color: #f7931d;
}
.ant-input:placeholder-shown:focus{
  border-color: #f7931d;
  box-shadow: 0 0 0 2px #FFDDB5;
}
.ant-picker:hover{
  border-color: #f7931d;
}
.ant-form-item .ant-mentions, .ant-form-item textarea.ant-input:hover{
  border-color: #f7931d;
}
.ant-form-item .ant-mentions, .ant-form-item textarea.ant-input:focus{
  border-color: #f7931d;
  box-shadow: 0 0 0 2px #FFDDB5;
}

/* Table */
.table-content{
  padding: 20px 0px;
}

/* Filter */
.ant-checkbox-checked .ant-checkbox-inner{
  background-color: #f7931d;
  border-color: #f7931d;
}
.ant-checkbox:hover{
  border-color: #f7931d;
}
.ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner{
  border-color: #f7931d;
}

html{
  scroll-behavior: smooth;
}

/* New added */
.header-section {
  background-color: #ffff;
  padding: 20px 20px 0 20px;
}
.header-section-card{
  justify-content: space-between;
}
.header-section-card-row{
  justify-content: space-between;
}
.header-section-apply-search-button{
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.header-section-search-bar-section {
  border: 1px #d9d9d9;
  border-radius: 2px;
  padding-right: 10px;
}
.vertical-align-center-button{
  display: flex;
  align-items: center;
}
.right-positioned-button{
  padding-left: 10px;
}

/* Card */
.ant-card-bordered{
  border: 1px solid #DADADA;
}
.ant-card-head{
  border-bottom: 1px solid #DADADA;
}

.quotation-delete-button{
  display: flex;
  padding-left: 10px;
  flex-direction: row;
  align-items: center;
}

.apply-filter-button{
  padding-top: 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.player-detail .ant-statistic-title {
  font-size: 14px;
  font-weight: 400;
}
.player-detail .ant-statistic-content-value {
  font-size: 16px;
  font-weight: 500;
}
.player-detail .ant-statistic-content {
  font-size: 16px;
  font-weight: 500;
}
.merchantBranchDetail .ant-list-item-meta-description {
  font-size: 14px;
  font-weight: 400;
}

.hover-cursorChange:hover {
  cursor: pointer;
}

.branch-details .ant-menu-item {
  margin: 0px !important;
}