/* Todo: make it responsive*/
.work-history-search-bar{
    width: 300px;
}

.consultant-details-information-white-area{
    background-color: white;
}

.personal-details-form-data{
    justify-content: space-between;
}


/* Todo: make it responsive*/
.work-details-statistics-card{
    height: 120px;
}

.work-details-statistics-card-title{
    color:rgba(0, 0, 0, 0.45);
    font-weight: 400;
    font-size: 14px;
}
.work-details-statistics-card-contents{
    font-weight: 500;
    font-size: 20px;
}
.work-details-statistics-card-footer{
    color:rgba(0, 0, 0, 0.45);
    font-weight: 400;
    font-size: 12px;
}
.work-details-statistics-card-redirects-text{
    font-weight: 400;
    color:rgba(0, 0, 0, 0.45);
    font-size: small;
}
.consultant-details-edit-work-info-row{
    padding: 10px 0;
    flex-direction: column;
}

/* Personal Details */
@media (max-width: 480px){
    .tags-content{
        display: flex;
        flex-direction: column;
        padding-right: 10px;
    }
}
.tags-content{
    display: flex;
    padding-right: 10px;
}

.download-personal-slip-history-div{
    padding-top: 15px;
}
.consultant-details-download-contents{
    background-color: white;
    padding: 20px 30px;
}

.hint-text{
    color:  rgba(0, 0, 0, 0.35);
    font-weight: 500;
    font-size: small;
}

.personal-info-title{
    font-weight: 500;
    padding: 5px  0 0 0;
}

.payslip-history-header {
    justify-content: space-between;
}