.dashboard-content-layout{
    padding: 20px;
    justify-content: space-between;
}
.dashboard-left-col-bottom-row{
    justify-content: space-between;
    padding-top: 15px;
}

/* general use css */
/* todo: responsive */
.dashboard-section-title{
    font-weight: 400;
    font-size: 16px;
}

/* Statistics Card */
.dashboard-statistics-card{
    height: 130px;
    justify-content: space-between;
}
.dashboard-statistics-card-title{
    color:rgba(0, 0, 0, 0.45);
    font-weight: 400;
    font-size: small;
}
.dashboard-statistics-card-contents{
    font-weight: 500;
    font-size: large;
}
.dashboard-statistics-card-redirects-text{
    font-weight: 400;
    color:rgba(0, 0, 0, 0.45);
    font-size: small;
}
.dashboard-statistics-card-footer-contents{
    color:rgba(0, 0, 0, 0.85);
    font-weight: 500;
    font-size: small;
    justify-content: space-between;
}

.financial-performance-overview-contents{
    background-color: white;
    padding: 20px;
}
.financial-performance-header{
    justify-content: space-between;
}
.psl-distribution-header{
    justify-content: space-between;
}


.AR-statistics-contents{
    background-color: white;
    padding: 10px;
}
.AR-top-header{
    justify-content: space-between;
}
.AR-contents{
    font-size: 36px;
    font-weight: 400;
    justify-content: center;
}

.psl-distribution-contents{
    min-height: 530px;
    background-color: white;
    padding: 15px;
}

.todo-contents{
    min-height: 885px;
    margin-top: 15px;
    background-color: white;
    padding: 15px;
}
.todo-tab-title{
    padding-right: 5px;
}

.performance-history-top-statistics{
    min-height: 530px;
    background-color: white;
    padding: 15px;
}
.performance-history-statistics-contents{
    justify-content: center;
}

/* Merchant- Sales Table */
.ant-table-thead th {
    font-weight: bold;
}

table tr:last-child td {
    border-bottom: none;
}

.ant-statistic-title {
    font-size: 16px;
    font-weight: 500;
}

.ant-statistic-content-value {
    font-size: 24px;
    font-weight: 600;
}

.ant-list-item-meta-description {
    font-size: 16px;
}