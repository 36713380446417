/* Header */
.header-layout {
  padding: 0 30px 0 20px !important;
  color: black !important;
  background: white !important;
  height: 48px;
  line-height: 45px !important;
}
.header-layout .header-text {
  display: flex;
  justify-content: flex-end;
}
.header-text > p {
  padding-right: 10px;
}

/* Sider */
@media (max-width: 700px) {
  .ant-layout-sider {
    width: max-content;
  }
}

/* Menu */
.menu-layout {
  background-color: #fff;
  height: 100vh;
}
.menu-layout .ant-menu-item {
  margin-top: 0 !important;
  font-size: 15px;
  color: #595959 !important;
}
.menu-layout .ant-menu-item::after {
  margin-top: 0 !important;
  border-right: 3px solid #f7931d !important;
}
.menu-layout .ant-menu-item-selected {
  background-color: #ffddb5 !important;
  color: #f7931d !important;
}
.menu-layout .ant-menu-item-active {
  color: #f7931d !important;
}
.menu-layout .ant-menu-item a:hover {
  color: #f7931d !important;
}
.menu-layout .ant-menu-item-selected a {
  color: #f7931d !important;
}
.ant-menu-submenu-title:hover{
  color: #f7931d;
}
.ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-expand-icon, .ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow{
  color: #f7931d;
}
.ant-menu-submenu-selected{
  color: #f7931d;
}

/* Breadcrumb */
.breadcrumb-layout {
  padding: 10px 20px;
}
.ant-breadcrumb {
  font-size: 16px;
  font-weight: 600;
}

.with-tabs-breadcrumb-layout {
  padding: 20px 20px 0 20px;
  height: 176px;
  background-color: #FFFFFF; 
}
.breadcrumb-title {
  margin-top: 30px;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
}

/* Content */
.content-layout {
  min-height: 93vh;
  background-color: #f5f5f5;
}

#components-layout-demo-responsive .logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.2);
}

.site-layout-sub-header-background {
  background: #fff;
}

.site-layout-background {
  background: #fff;
}

/* Drawer parent component */
.drawer-wrap {
  position: relative;
  overflow: hidden;
}

/* Dropdown */
.ant-dropdown-menu  {
  border-radius: 20px;
}

.ant-tabs .ant-list-item-meta-description {
  font-size: 14px;
  font-weight: 400;
}
.ant-tabs .ant-list-item-meta-title {
  font-size: 16px;
  font-weight: 500;
}