.header-layout{
    background-color: white;
}

#components-layout-demo-responsive .logo {
    height: 32px;
    margin: 16px;
    background: rgba(255, 255, 255, 0.2);
}

.consultant-management-layout{
    background: #ECEEF1 !important;
}

.cm-top-center-col{
    text-align: center;
}

@media(max-width: 600px){
    .consultant-management-header-col-1{
        width: 100%;
        padding: 10px 0;
    }
    .consultant-management-header-col-2{
        width: 100%;
    }
}

