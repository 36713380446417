.wrapper1{
  padding-top: 15px;
  padding-bottom: 50px;
  align-items: center;
}

.wrapper2{
  height: 200px;
}

.wrapper3{
  height: 260px;
}

.wrapper4{
  padding-top: 100px;
  align-items: center;
}

h6{
  color: #000000 45%;
  font-weight: 400;
}

.ant-card-head-title{
  padding: 16px 0px 0px;
}

h4{
  color: #00000073;
  font-weight: 500;
}

h3{
  color: #F7931D;
  text-align: center;
}

.site-form-item-icon{
  color: #F7931D;
}

.login{
  background-color: #f0f2f5;
}

.login-layout{
  background: #f0f2f5;
}

.login-content{
  background: #f0f2f5;
}

.login-footer{
  background: #ffffff;
}

.login-footer.h5{
  color: #000000;
}

.loading-logo{
  text-align: center;
}

.login-form-button{
  background-color: #F7931D;
  color: white;
}
.login-form-button:focus{
  background-color: #ffb661;
  border-color: #ffb661;
  color: white;
}
.login-form-button:hover{
  background-color: #ffb661;
  border-color: #ffb661;
  color: white;
}

/* .ant-checkbox:hover{
  background-color: #ffb661;
  border-color: #ffb661;
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #F7931D;
  border-color: #F7931D;
}
.ant-checkbox-checked .ant-checkbox-inner:hover{
  border-color: #ffb661;
}
.ant-checkbox-checked .ant-checkbox-inner:focus{
  background-color: #ffb661;
  border-color: #ffb661;
}
.ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner{
  border-color: #ffb661;
  border-color: #ffb651;
}
.ant-checkbox-wrapper-checked:hover{
  border-color: red
} */